import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-8001_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8001 Full HD Usermanual",
  "path": "/Indoor_Cameras/IN-8001_HD/Usermanual/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8001 Full HD Indoor IP Camera Usermanual",
  "image": "../P_SearchThumb_IN-8001HD.png",
  "social": "/images/Search/P_SearchThumb_IN-8001HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-8001 Full HD Usermanual' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8001 HD is the first INSTAR camera with the new 1080p chipset.' image='/images/Search/P_SearchThumb_IN-8001HD.png' twitter='/images/Search/P_SearchThumb_IN-8001HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8001_HD/Handbuch/' locationFR='/fr/Indoor_Cameras/IN-8001_HD/Usermanual/' crumbLabel="Usermanual" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-8001-full-hd-usermanual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-8001-full-hd-usermanual",
        "aria-label": "in 8001 full hd usermanual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8001 Full HD Usermanual`}</h1>
    <h2 {...{
      "id": "online-product-description",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#online-product-description",
        "aria-label": "online product description permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Online Product Description`}</h2>
    <h3 {...{
      "id": "table-of-content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#table-of-content",
        "aria-label": "table of content permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Table of Content`}</h3>
    <ul>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/" mdxType="Link">Overview</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Usermanual/" mdxType="Link">Online Usermanual</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Quick_Installation/" mdxType="Link">Camera Installation</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Safety_Warnings/" mdxType="Link">Safety Warning</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Product_Features/" mdxType="Link">Product Features</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Technical_Specifications/" mdxType="Link">Technical Specifications</Link>
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/" mdxType="Link">Web User Interface</Link>
      </li>
      <li parentName="ul">
        <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/" mdxType="Link">Software Update</Link>
      </li>
      <li parentName="ul">
        <Link to="/Downloads/Indoor_Cameras/IN-8001_HD/" mdxType="Link">Downloads</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Video_Streaming/" mdxType="Link">Video Streaming</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Camera_Reset/" mdxType="Link">Factory Reset</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Lense_Adjustment/" mdxType="Link">Lense Adjustment</Link>
      </li>
      <li parentName="ul">
        <Link to="/Indoor_Cameras/IN-8001_HD/Warranty/" mdxType="Link">Warranty & Disposal</Link>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-8001 Full HD is the first INSTAR HD 1080p pan & tilt camera with an integrated infrared motion detector. The camera can easily be controlled through your smartphone from inside your network and also over the internet.`}</p>
    <h2 {...{
      "id": "download-our-print-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#download-our-print-manual",
        "aria-label": "download our print manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download our Print Manual`}</h2>
    <OutboundLink href="https://wiki.instar.com/dl/IN-8001HD/QuickInstallation-1080p-IN-8001HD.pdf" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill mdxType="EuiButton">PDF User Manual</EuiButton></OutboundLink>
    <h2 {...{
      "id": "frequently-asked-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#frequently-asked-questions",
        "aria-label": "frequently asked questions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frequently Asked Questions`}</h2>
    <ul>
      <li parentName="ul">
        <Link to="#online-product-description" mdxType="Link">Online Product Description</Link>
- <Link to="#table-of-content" mdxType="Link">Table of Content</Link>
      </li>
      <li parentName="ul">
        <Link to="#available-youtube-videos" mdxType="Link">Available Youtube Videos</Link>
      </li>
      <li parentName="ul">
        <Link to="#download-our-print-manual" mdxType="Link">Download our Print Manual</Link>
      </li>
      <li parentName="ul">
        <Link to="#frequently-asked-questions" mdxType="Link">Frequently Asked Questions</Link>
- <Link to="#how-to-save-camera-recordings" mdxType="Link">How to save Camera Recordings</Link>
- <Link to="#camera-access-via-smartphone-app" mdxType="Link">Camera-Access via Smartphone-App</Link>
- <Link to="#external-cables-of-the-outdoor-cameras" mdxType="Link">External Cables of the Outdoor-Cameras</Link>
- <Link to="#heater-for-the-outdoor-cameras" mdxType="Link">Heater for the Outdoor-Cameras</Link>
- <Link to="#zoom-lense" mdxType="Link">Zoom-Lense</Link>
- <Link to="#battery-andor-sim-card" mdxType="Link">Battery and/or SIM-Card</Link>
- <Link to="#length-of-the-alarm-videos" mdxType="Link">Length of the Alarm-Videos</Link>
- <Link to="#synology-surveillance-station-and-qnap-nas" mdxType="Link">Synology Surveillance Station and QNAP NAS</Link>
      </li>
    </ul>
    <h3 {...{
      "id": "how-to-save-camera-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-save-camera-recordings",
        "aria-label": "how to save camera recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to save Camera Recordings`}</h3>
    <p>{`In total there are five different ways to save the `}<Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link">{`Video Recordings`}</Link>{` of your camera:`}</p>
    <ol>
      <li parentName="ol">{`All of our HD IP Cameras have a SD-Card with a capacity of 4GB, 8GB or 16GB, depending on the camera model. `}<OutboundLink href="https://www.instar.com/support/help-tools" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`here`}</OutboundLink>{` you can calculate how long it takes until the storage of the SD-Card is full. The oldest recordings on the camera will be overwritten by default (Ring Storage). `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Access your SD Card`}</Link>{` with our smart phone app InstarVision for `}<Link to="/Software/Android/InstarVision/Recording/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/InstarVision/iPhone/Recording/" mdxType="Link">{`iPhone`}</Link>{`, `}<Link to="/Software/iOS/InstarVision/iPad/Recording/" mdxType="Link">{`iPad`}</Link>{`, `}<Link to="/Software/Other_Platforms/InstarVision_Blackberry/" mdxType="Link">{`Blackberry`}</Link>{`, `}<Link to="/Software/Windows/InstarVision/Windows_Phone/Recording/" mdxType="Link">{`Windows Phone`}</Link>{` and `}<Link to="/Software/Windows/InstarVision/Metro/Recording/" mdxType="Link">{`Windows 8/10`}</Link>{`.`}</li>
      <li parentName="ol">{`We offer a `}<strong parentName="li">{`Cloud`}</strong>{`-solution to easily store recordings of your INSTAR Cameras. The `}<Link to="/Motion_Detection/INSTAR_Cloud/" mdxType="Link">{`INSTAR Cloud`}</Link>{` offers an easy-to-access interface and a good way to manage your recordings. If you are afraid that your camera and SD-Card could be destroyed, the INSTAR Cloud will give you the means to secure your recordings.`}</li>
      <li parentName="ol">{`You can use the `}<Link to="/Software/Windows/InstarVision/" mdxType="Link">{`InstarVision 2.0 Surveillance Center`}</Link>{`, a `}<strong parentName="li">{`Windows PC Software`}</strong>{` made by us. With this Software it is not only possible to show the live video of all your cameras at the same time, you can also start recordings and save them on your computer.`}</li>
      <li parentName="ol">{`It is also possible to use the `}<strong parentName="li">{`FTP`}</strong>{` (File Transfer Protocol) service to send your recordings to other devices, for example to an `}<Link to="/Motion_Detection/Router_as_a_FTP_Server/" mdxType="Link">{`USB-Stick Connected To Your Router`}</Link>{` or a hard-drive in your network.`}</li>
      <li parentName="ol">{`If you want to make permanent recordings you can also use `}<strong parentName="li">{`NAS Systems`}</strong>{`, e.g. `}<Link to="/Software/Other_Platforms/Synology/" mdxType="Link">{`Synology`}</Link>{` or `}<Link to="/Software/Other_Platforms/QNAP/" mdxType="Link">{`QNAP`}</Link>{`. These have their own surveillance software and support all our camera models.`}</li>
    </ol>
    <p><strong parentName="p">{`Addition`}</strong>{`: You can also activate the `}<Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link">{`Email-Service`}</Link>{`, to receive snapshots by email after an alarm was triggered.`}</p>
    <h3 {...{
      "id": "camera-access-via-smartphone-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#camera-access-via-smartphone-app",
        "aria-label": "camera access via smartphone app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera-Access via Smartphone-App`}</h3>
    <p>{`Use our free App InstarVision for `}<Link to="/Software/Android/InstarVision/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/InstarVision/iPhone/" mdxType="Link">{`iPhone`}</Link>{`, `}<Link to="/Software/iOS/InstarVision/iPad/" mdxType="Link">{`iPad`}</Link>{`, `}<Link to="/Software/Other_Platforms/InstarVision_Blackberry/" mdxType="Link">{`Blackberry`}</Link>{`, `}<Link to="/Software/Windows/InstarVision/Windows_Phone/" mdxType="Link">{`Windows Phone`}</Link>{` and `}<Link to="/Software/Windows/InstarVision/Metro/" mdxType="Link">{`Windows 8/10`}</Link>{` to access the live video of your IP Camera. Moreover, you can access your SD Card and check alarm recordings and use functions such as activating-/ deactivating the motion detection or use the camera and smart phone as an intercommunication system.`}</p>
    <h3 {...{
      "id": "external-cables-of-the-outdoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#external-cables-of-the-outdoor-cameras",
        "aria-label": "external cables of the outdoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`External Cables of the Outdoor-Cameras`}</h3>
    <p>{`Except for our FullHD-models, all of our cameras have a so called `}<strong parentName="p">{`cable-tree`}</strong>{`, which consists of all cable-connectors for the camera. This cable tree is 80cm long and relatively thick. If you don´t want to drill a big hole through your wall, you can `}<strong parentName="p">{`remove those cables`}</strong>{` from your camera and then put the "thin end" through the wall. A video manual can be found here - `}<Link to="/Outdoor_Cameras/IN-5905_HD/Video/Cable/" mdxType="Link">{`IN-5905 HD`}</Link>{` and `}<Link to="/Outdoor_Cameras/IN-5907_HD/Video/Cable/" target="_blank" rel="noopener noreferrer" mdxType="Link">{`IN-5907 HD`}</Link>{`.`}</p>
    <p>{`We strongly advice against cutting the cables, as you will not only lose the guarantee for your camera, you also risk damaging of your camera. A solution would be to use a weatherproof cable-box to store the cable-tree.`}</p>
    <h3 {...{
      "id": "heater-for-the-outdoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#heater-for-the-outdoor-cameras",
        "aria-label": "heater for the outdoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Heater for the Outdoor-Cameras`}</h3>
    <p>{`Whether a heater is necessary for your camera depends on the location you want to install it in. Our cameras can be used down to -5 Degree Celsius. If the temperatures get even lower, the camera might not be damaged. However, it is possible that the camera will turn off and cannot be accessed until normal operating temperatures are reached again. The installation of a heater would guarantee the permanent operation of the camera. You can find heaters for the camera models `}<OutboundLink href="https://www.instar.de/accessories/heaters/heater-for-in-7011hd-1436.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-7011 HD`}</OutboundLink>{`, `}<OutboundLink href="https://www.instar.de/accessories/heaters/heater-for-in-5907hd-1551.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-5907 HD`}</OutboundLink>{`, `}<OutboundLink href="https://www.instar.de/accessories/heaters/heater-for-in-5905hd.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-5905 HD`}</OutboundLink>{`, `}<OutboundLink href="https://www.instar.de/accessories/heaters/heater-for-in-4010v2.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-4010 v2`}</OutboundLink>{`, `}<OutboundLink href="https://www.instar.de/accessories/heaters/heater-for-in-4132.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-4010/11`}</OutboundLink>{`, `}<OutboundLink href="https://www.instar.de/accessories/heaters/heater-for-in-4133.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-2908`}</OutboundLink>{` and `}<OutboundLink href="https://www.instar.de/accessories/heaters/heater-for-in-2904-in-2905-in-2969.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`IN-2904/5/7`}</OutboundLink>{` in our shop.`}</p>
    <h3 {...{
      "id": "zoom-lense",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#zoom-lense",
        "aria-label": "zoom lense permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zoom-Lense`}</h3>
    <p>{`All of our HD- and FullHD-Cameras (except the IN-9020 FHD) do not have optical zoom lenses, but do offer digital zoom. Due to their resolution our HD cameras still offer a better quality with their 3-times digital zoom than our VGA-cameras with their "lossless" optical zoom.`}</p>
    <h3 {...{
      "id": "battery-andor-sim-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#battery-andor-sim-card",
        "aria-label": "battery andor sim card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Battery and/or SIM-Card`}</h3>
    <p>{`Our cameras do not have an internal `}<strong parentName="p">{`Battery`}</strong>{`. Therefore, they must constantly be connected via power adapter.
Our cameras do not have a `}<strong parentName="p">{`SIM-Card-Slot`}</strong>{`. If you want to use the camera at a location without internet access, you can use a mobile LTE-Router. The camera can then be connected to the router and will have access to the internet.`}</p>
    <h3 {...{
      "id": "length-of-the-alarm-videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#length-of-the-alarm-videos",
        "aria-label": "length of the alarm videos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Length of the Alarm-Videos`}</h3>
    <p>{`The alarm video starts 3 seconds before the alarm event and ends 15 seconds after - in total the alarm video is 17-18s. If the movement continues after the recording, a new alarm event will be triggered and a new 15s video will be recorded.`}</p>
    <h3 {...{
      "id": "time-lapse-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#time-lapse-recordings",
        "aria-label": "time lapse recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Time Lapse Recordings`}</h3>
    <p>{`With all of our cameras you can create timelapse videos, for example if you want to document the progress for a construction site. Activate the photo series inside the WebUI to take a snapshot in scheduled intervals, which you can afterwards combine to a timelapse video. Check our `}<Link to="/Software/Windows/InstarVision/Record/Photoseries/" mdxType="Link">{`InstarVision Time Lapse Guide`}</Link>{` for detailed information on how to create a time lapse video. Alternatively, you can also use free software like `}<Link to="/Frequently_Asked_Question/Video_Editing_with_Davinci_Resolve/" mdxType="Link">{`Davinci Resolve`}</Link>{` or on-board tools that `}<Link to="/Frequently_Asked_Question/Time_lapse/" mdxType="Link">{` macOS and Windows 10`}</Link>{` come with.`}</p>
    <h3 {...{
      "id": "synology-surveillance-station-and-qnap-nas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#synology-surveillance-station-and-qnap-nas",
        "aria-label": "synology surveillance station and qnap nas permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Synology Surveillance Station and QNAP NAS`}</h3>
    <p>{`Synology and QNAP have their own surveillance software and can use the live video of our cameras. All of our HD- and FullHD Cameras are compatible. For the implementation of our cameras into their surveillance station you can follow our `}<Link to="/Software/Other_Platforms/Synology/" mdxType="Link">{`Manual`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      